<template>
     <div class="main">
          <div style="text-align: center;width:100%;">
               <h1>FAQs</h1>

               <VueFaqAccordion :items="faqs" class="faqs" />
               <!-- <div class="columns is-multiline">
                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">Describe your product in 3 words.</h3>
                              <div class="ans">
                                   Slide. Design. Easy.
                              </div>
                         </div>
                    </div>
                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">What is the philosophy behind Slyde.ai?</h3>
                              <div class="ans">
                                   'Good design is good business. ' -- Thomas Watson Jr.
                              </div>
                         </div>
                    </div>
                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">What is the purpose of Slyde.ai?</h3>
                              <div class="ans">
                                   Slyde.ai aims to democratize good design in business communication. For the average Joe and the discerning Jane.
                              </div>
                         </div>
                    </div>

                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">What's your pricing model? How do I pay?</h3>
                              <div class="ans">
                                   There's no cost to use Slyde.ai while we're in beta. You can request early access by signing up for the beta.
                              </div>
                         </div>
                    </div>

                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">
                                   How did you come up with the idea for Slyde.ai?
                              </h3>
                              <div class="ans">
                                   <p>
                                        Necessity is the mother of Slyde.ai. Slide creation is a burden for 9 out of 10 users who lack design skills
                                        to create impactful content quickly.
                                   </p>

                                   <p>
                                        We hate the effort of editing, re-editing, and re-re-editing slides. We hate looking at bad slides even more.
                                   </p>

                                   <p>
                                        Slyde.ai makes it easy for professionals to focus on building great content & narrative while automating slide
                                        format & design.
                                   </p>
                              </div>
                         </div>
                    </div>

                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">What makes your product special?</h3>
                              <div class="ans">
                                   Slyde.ai brings the power of cognitive AI technology and design thinking principles together to transform
                                   communication. It makes content consumable, accessible, and aesthetic -- driving productivity and creating more
                                   impact.
                              </div>
                         </div>
                    </div>

                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">Is my data private and secure?</h3>
                              <div class="ans">
                                   Slyde.ai is in beta, and we are hard at optimizing our AI/design models. We will not share your data outside
                                   Slyde.ai and take all precautions to keep data encrypted and secure. At this point, we recommend you don't upload
                                   confidential or sensitive personal information.
                              </div>
                         </div>
                    </div>

                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">When do you expect to go live / out of beta?</h3>
                              <div class="ans">
                                   We believe we can launch Slyde.ai for the general public in mid-2021. Until then, we welcome you to sign up for
                                   beta access to view our offering and continue receiving news/updates. Plus, you'll get early-bird pricing once we
                                   launch to market.
                              </div>
                         </div>
                    </div>
                    <div class="column is-half">
                         <div class="faq">
                              <h3 class="qn">What file formats do you support at this time?</h3>
                              <div class="ans">
                                   We support Microsoft Powerpoint (.ppt and .pptx) files at the moment. Apple Keynote and Google Slides are on the
                                   roadmap. At this point, you can export to .pptx and then upload.
                              </div>
                         </div>
                    </div>
               </div> -->
               <!-- <div style="">
                    <b-collapse class="card faq" animation="slide" aria-id="contentIdForA11y3">
                         <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                              <template #trigger="props">
                                   <div class="card-header" role="button" aria-controls="contentIdForA11y3">
                                        <h3 class="card-header-title qn">
                                             Is my data private and secure?
                                        </h3>
                                        <a class="card-header-icon">
                                             <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
                                        </a>
                                   </div>
                              </template>

                              <div class="card-content">
                                   <div class="content">
                                        Slyde.ai is in beta, and we are hard at optimizing our AI/design models. We will not share your data outside
                                        Slyde.ai and take all precautions to keep data encrypted and secure. At this point, we recommend you don't
                                        upload confidential or sensitive personal information.
                                   </div>
                              </div>
                         </b-collapse>
                    </b-collapse>

                    <div class="faq">
                         <h3 class="qn">Is my data private and secure?</h3>
                         <div class="ans">
                              Slyde.ai is in beta, and we are hard at optimizing our AI/design models. We will not share your data outside Slyde.ai
                              and take all precautions to keep data encrypted and secure. At this point, we recommend you don't upload confidential or
                              sensitive personal information.
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">When do you expect to go live / out of beta?</h3>
                         <div class="ans">
                              We believe we can launch Slyde.ai for the general public in mid-2021. Until then, we welcome you to sign up for beta
                              access to view our offering and continue receiving news/updates. Plus, you'll get early-bird pricing once we launch to
                              market.
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">What's your pricing model? How do I pay?</h3>
                         <div class="ans">
                              There's no cost to use Slyde.ai while we're in beta. You can request early access by signing up for the beta.
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">What is the philosophy behind Slyde.ai?</h3>
                         <div class="ans">
                              'Good design is good business. ' -- Thomas Watson Jr.
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">
                              How did you come up with the idea for Slyde.ai?
                         </h3>
                         <div class="ans">
                              <p>
                                   Necessity is the mother of Slyde.ai. Slide creation is a burden for 9 out of 10 users who lack design skills to
                                   create impactful content quickly.
                              </p>

                              <p>
                                   We hate the effort of editing, re-editing, and re-re-editing slides. We hate looking at bad slides even more.
                              </p>

                              <p>
                                   Slyde.ai makes it easy for professionals to focus on building great content & narrative while automating slide
                                   format & design.
                              </p>
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">What makes your product special?</h3>
                         <div class="ans">
                              Slyde.ai brings the power of cognitive AI technology and design thinking principles together to transform communication.
                              It makes content consumable, accessible, and aesthetic -- driving productivity and creating more impact.
                         </div>
                    </div>

                    <div class="faq">
                         <h3 class="qn">What file formats do you support at this time?</h3>
                         <div class="ans">
                              We support Microsoft Powerpoint (.ppt and .pptx) files at the moment. Apple Keynote and Google Slides are on the
                              roadmap. At this point, you can export to .pptx and then upload.
                         </div>
                    </div>
               </div> -->
          </div>
     </div>
     <!-- <b-button @click="clickMe" class="coral"> Click Me</b-button>
          <b-button type="is-coral" class="worksans">Primary</b-button> -->
</template>

<script>
     import axios from "axios";
     import VueFaqAccordion from "vue-faq-accordion";

     export default {
          data() {
               return {
                    email: "",
                    faqs: [
                         {
                              title: "Describe your product in 3 words.",
                              value: "Slide. Design. Easy.",
                         },

                         {
                              title: "What is the purpose of Slyde.ai?",
                              value:
                                   "Slyde.ai aims to democratize good design in business communication. For the average Joe and the discerning Jane.",
                         },
                         {
                              title: "What is the philosophy behind Slyde.ai?",
                              value: "'Good design is good business. ' -- Thomas Watson Jr.",
                         },
                         {
                              title: "How did you come up with the idea for Slyde.ai?",
                              value: `Necessity is the mother of Slyde.ai. Slide creation is a burden for 9 out of 10 users who lack design skills
                                      to create impactful content quickly.
                                      <br><br>

                                      We hate the effort of editing, re-editing, and re-re-editing slides. We hate looking at bad slides even more.
                                      <br><br>

                                      Slyde.ai makes it easy for professionals to focus on building great content & narrative while automating slide
                                      format & design.
                                     `,
                         },

                         {
                              title: "What makes your product special?",
                              value: ` Slyde.ai brings the power of cognitive AI technology and design thinking principles together to transform
                                   communication. It makes content consumable, accessible, and aesthetic -- driving productivity and creating more
                                   impact.`,
                         },
                         {
                              title: "Is my data private and secure?",
                              value: `Slyde.ai is in beta, and we are hard at optimizing our AI/design models. We will not share your data outside
                                   Slyde.ai and take all precautions to keep data encrypted and secure. At this point, we recommend you don't upload
                                   confidential or sensitive personal information.`,
                         },
                         {
                              title: "What's your pricing model? How do I pay?",
                              value: "There's no cost to use Slyde.ai while we're in beta. You can request early access by signing up for the beta.",
                         },

                         {
                              title: "When do you expect to go live / out of beta?",
                              value: `We believe we can launch Slyde.ai for the general public in mid-2021. Until then, we welcome you to sign up for
                                   beta access to view our offering and continue receiving news/updates. Plus, you'll get early-bird pricing once we
                                   launch to market.`,
                         },

                         {
                              title: "What file formats do you support at this time?",
                              value: `  We support Microsoft Powerpoint (.ppt and .pptx) files at the moment. Apple Keynote and Google Slides are on the
                                   roadmap. At this point, you can export to .pptx and then upload.`,
                         },

                         {
                              title: "Do you have a plugin I can use on my laptop? ",
                              value: `A plugin will be available soon. You can use the web upload option meanwhile. `,
                         },

                         {
                              title: "Can I provide my corporate branding/guidelines so that you can check for compliance?",
                              value: `Yes, you can provide your corporate branding/guidelines soon. We are excited to hear about these and other requirements you have in mind. Please let us know your thoughts via our Contact Us form. `,
                         },
                    ],
               };
          },
          components: {
               VueFaqAccordion,
          },
     };
</script>

<style lang="scss" scoped>
     @import "@/assets/styles/variables.scss";

     .main {
          display: block;

          min-height: auto;
          padding-bottom: 50px;
     }

     h1 {
          padding-bottom: 50px;
     }
</style>
<style lang="scss">
     .faqs {
          // .qn {
          //      font-size: 27px;
          //      @media only screen and (max-width: 600px) {
          //           font-size: 19px;
          //      }
          // }
          // .ans {
          //      margin-top: 20px;

          //      @media only screen and (max-width: 600px) {
          //           margin-top: 10px;
          //      }
          //      font-size: 17px;
          //      color: #73738d;
          //      max-width: 450px;

          //      p {
          //           margin-bottom: 10px;
          //      }
          //      min-height: 50px;
          // }

          .accordion__toggle-button::before,
          .accordion__toggle-button::after {
               background: #73738d !important;
          }

          .accordion__title-text {
               color: rgba(0, 0, 0, 0.7);
               font-size: 20px;
               font-weight: 500;
               text-align: left;

               @media only screen and (max-width: 600px) {
                    font-size: 19px !important;
                    text-align: left;
               }
          }

          .accordion__value * {
               color: #5a5a6d;
               font-size: 17px;
          }
          .accordion {
               display: block;
               margin: auto !important;
          }
          .faq-wrapper {
               display: block;
               margin: auto !important;
               padding: 10px 30px !important;

               @media only screen and (max-width: 600px) {
                    padding: 10px !important;
               }
          }
     }

     .darkmode {
          .faqs {
               .accordion__toggle-button::before,
               .accordion__toggle-button::after {
                    background: white !important;
               }

               .accordion__title-text {
                    color: white;
               }

               .accordion__value * {
                    color: #a7a7b3;
               }
          }
     }
</style>
